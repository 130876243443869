@media screen and (max-width:1150px){
#burger-menu {
    cursor: pointer;
    height: 27px;
    width: 27px;
    margin: 15px;
    right: 0;
    overflow: visible;
    position: relative;
    z-index:12;
    top: 0;
    position: fixed;
  }
  #burger-menu span,
  #burger-menu span:before,
  #burger-menu span:after {
    background: #000;
    display: block;
    height: 4px;
    opacity: 1;
    position: absolute;
    transition: 0.3s ease-in-out;
    z-index: 11;
  }
  #burger-menu span:before,
  #burger-menu span:after {
    content: "";
  }
  #burger-menu span {
    right: 0px;
    top: 13px;
    width: 27px;
  }
  #burger-menu span:before {
    left: 0px;
    top: -10px;
    width: 27px;
  }
  #burger-menu span:after {
    left: 0px;
    top: 10px;
    width: 27px;
  }
  
  #burger-menu.close span {
    transform: rotate(-45deg);
    top: 13px;
    width: 27px;
    background-color: white;
  }
  #burger-menu.close span:before {
    top: 0px;
    transform: rotate(90deg);
    width: 27px;
    background-color: white;
  }
  #burger-menu.close span:after {
    top: 0px;
    left:0;
    transform: rotate(90deg);
    opacity:0;
    width:0;
    background-color: white;
  }
  
  #menu{
    z-index:10;
    min-width:100%;
    min-height:100%;
    position: fixed;
    top:0;
    height:0;
    visibility: hidden;
    opacity: 0;
    text-align:center;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    ul{
        padding-left: 0;
        font-family: "Poppins-Regular";
        width: 85%;
        margin: 0 auto;
        transform: translateY(-10%);
        display: flex;
        height: 50vh;
        flex-direction: column;
        justify-content: space-between;
    }
  }
  
  #menu.overlay{
    visibility: visible;
    opacity: 1 !important;
    transform: unset!important;
    background:#789FA8;
    position: fixed;
  }
  
  #menu li{
    list-style:none;
  }
  #menu a{
    color:#fff;
    display:block;
    font-size: 32px;
    margin-bottom:30px;
    text-decoration:none;
  }
}

footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    p 
    {
        color:#789FA8;
        font-family: 'Poppins-Light';
    }
    hr{
      display: block;
      height: 1px;
      border: 0;
      background-color: white;
      border-top: 3px solid #789FA8;
      margin: 1em auto;
      padding: 0;
      width: 60vw!important;
    }
}


#logoLexmovice{
  position: fixed;
  top:0px;
  left:25px;
  z-index: 10;
  img{
    width: 15vw;
    height: auto;
  }
}

@media screen and (min-width:1150px) {
  section.Honoraires{
    padding-top:0;
  }
  #logoLexmovice{
    z-index: 150;
    img{
    width: 12vh;
    }
  }
  body #root>div:not(#Home) nav#menu{
    height: 12vh;
    position: fixed;
    z-index: 100;
    top:0;
    background: white;
    box-shadow: 0px 0px 13px 5px rgba(120,159,168,0.33);
    ul{
      margin-right: 2.5vw;
    }
    //border-bottom: 2px solid #789FA8;
  }
  body div#container,.Domaines-content1 .container{
    margin-top: 14vh;
  }
  footer {
    flex-direction: row;
    flex-wrap: wrap;
    hr{
      width: 60vw!important;
      margin-right:20vw;
      margin-left:20vw;
    }
    p{
      min-width: 15%;
    }
  }
}
a{
  color:#789FA8;
}