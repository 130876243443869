@font-face {
    font-family: "Azonix";
    src: url("./../fonts/Azonix.otf") format("otf");
  }
  @font-face {
    font-family: "Poppins-Light";
    src: url("./../fonts/Poppins-Light.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: url("./../fonts/Poppins-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "Poppins";
    src: url("./../fonts/Poppins-Medium.ttf") format("truetype");
  }
  @font-face {
    font-family: "Trajanus";
    src: url("./../fonts/TrajanusBricks.ttf") format("truetype");
  }
#mention{
    width: 90%;
    margin:0 auto;
    text-align: justify!important;
    height: 100%;
    p.has-text-align-left{
        text-align: justify;
        width: 100%;
        strong{
            font-weight: bold;
            color:#789FA8;
        }
    }
    a{
        color:#789FA8;
    }
    strong{
        font-weight: normal;
        color: black;
        font-family: BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif
    }
    h2,h1{
        font-weight: bold;
        color:#789FA8;
        text-align: left!important;
        width: 100%;
        strong{
            font-weight: bold;
        }
    }
    p{
        text-align: justify!important;
        width: 100%;
    }
    h1{
        text-align: center !important;
        margin-top: 5vh;
    }
    ul{
        width: 100%;
    }
}