@font-face {
    font-family: "Azonix";
    src: url("./../fonts/Azonix.otf") format("otf");
}
@font-face {
    font-family: "Poppins-Light";
    src: url("./../fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins-Regular";
    src: url("./../fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins";
    src: url("./../fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Trajanus";
    src: url("./../fonts/TrajanusBricks.ttf") format("truetype");
}
body 
{
    padding: 0;
    margin:0;

    #container-contacts 
    {
        display: flex;
        justify-content: center;
        height:100%;
        width:100%;
        flex-direction: column;
        text-align: justify;

        section 
        {
            height: 100vh;
            width:100%;
            display: flex;

            #Description 
            {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 50%;
            #Title 
            {
                h1 
                {
                    font-family: 'Poppins';
                    color: #789FA8;
                    font-size: 2em;
                }
            }
            article 
            {
                h2 
                {
                    font-family: 'Poppins-Regular';
                    color: #789FA8;
                    font-size: 1.5em;
                }
                p 
                {
                    font-family: 'Poppins-Light';
                    font-size: 1.3em;

                    span 
                    {
                        font-family: 'Poppins';
                        text-align: left;
                        &.min{
                            font-family: 'Poppins-Light';
                            font-size: 12px;
                        }
                    }
                    a{
                        color:black;
                        text-decoration: none;
                    }
                }
            }
        }
        #Photo
        {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            width: 50%;
            
            
            img 
            {
                width: 18vw;
                height: auto;
                margin-top: 15vh;
            }
            figure 
            {
                color:#789FA8;
                font-family: 'Poppins-Light';
                width: 220px;
            }
            p 
            {
                font-family: 'Poppins-Light';
                width: 90%;
            }
        }
        }
        #TV
        {
            height:100vh;
            width:100%;
            flex-direction: column;
            
            #Transports 
            {
                height:100%;
                width:100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left:40px;

                span{
                    font-family: "Poppins";
                }
                p{
                    font-family: "Poppins-Light";
                }

                #Titre_Transports 
                {
                    display: flex;
                    justify-content: flex-start;
                    flex-direction: column;
                    width: 100%;

                    h2
                    {
                        font-family: 'Poppins-Regular';
                        color: #789FA8;
                        font-size: 1.5em;
                    }
                    h3 
                    {
                        font-family: 'Poppins-Regular';
                        color: #789FA8;
                        font-size: 1.2em;
                    }
                }
                article 
                {
                    display: flex;
                    flex-direction: column;
                    align-items: baseline;
                    article 
                    {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: row;
                        &:nth-child(3){
                            img{
                                transform: scale(1.2);
                            }
                        }
                        img 
                        {
                            height:auto;
                            width:20px;
                        }
                        p 
                        {
                            font-size: 'Poppins-Light';
                            margin-left: 10px;
                        }
                    }
                }
            }
            #Velo 
            {
                height:100%;
                width:100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left:40px;

                span{
                    font-family: "Poppins";
                }
                p{
                    font-family: "Poppins-Light";
                }

                #Titre_velo 
                {
                    h3 
                    {
                        font-family: 'Poppins-Regular';
                        color: #789FA8;
                        font-size: 1.2em;
                    }
                }
                article 
                {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    img 
                    {
                        height:16px;
                        width:22px;
                    }
                    p 
                    {
                        font-size: 'Poppins-Light';
                        margin-left: 10px;
                    }
                }
                
                #Titre_voiture
                {
                    h3 
                    {
                        font-family: 'Poppins-Regular';
                        color: #789FA8;
                        font-size: 1.2em;
                    }
                }
            }
            #Lieu 
            {
                height:100%;
                width:100%;
                display: flex;
                flex-direction: column;

                span{
                    font-family: "Poppins";
                }
                p{
                    font-family: "Poppins-Light";
                }
                
                #Title_lieu
                {
                    h2 
                    {
                        font-family: 'Poppins-Regular';
                        color: #789FA8;
                        font-size: 1.5em;
                    }
                }
                #GMap-GStreet
                {
                    display: flex;

                    #GoogleMap 
                    {
                        height: 100%;
                        width: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
                        img 
                        {
                            height:450px;
                            width:650px;
                        }
                    }
                    #GoogleStreetView
                    {
                        height: 100%;
                        width: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
    
                        img 
                        {
                            height:450px;
                            width:650px;
                        }
                    }
                }
                hr 
                {
                    width:150px;
                    color:#789FA8;
                }
            }
            
            
        }


    }
}
@media screen and (max-width:1150px){
    body #container-contacts section #Description{
        text-align: left!important;
    }
    body #container-contacts section #Photo img{
        width: 70vw;
        height: auto;
        margin-top: 0;
    }
    body #container-contacts{
        #who{
            display: flex;
            flex-direction: column;
            height: unset;
            width: 90%;
            margin:0 auto;
            &>section{
                width: 100%;
                height: unset;
            }
        }
        #TV{
            width: 90%;
            margin:0 auto;
            #Transports, #Velo, #Lieu{
                width: auto;
                margin-left: 0;
            }
            #Lieu #GMap-GStreet{
                flex-direction: column;
                #GoogleMap,#GoogleStreetView{
                    width: 110%;
                    margin-left: -5%;
                }
            }
        }
    }
}

@media screen and (max-width:650px){
    body #container-contacts #TV #Lieu #GMap-GStreet #GoogleStreetView img{
        width: 100%;
        height: auto;
    }
}

@media screen and (min-width:800px){
    body #container-contacts #TV #Lieu #Title_lieu h2{
        margin-left: 42px;
    }
}
@media screen and (min-width:800px) and (max-width:1450px){
    body #container-contacts #TV #Lieu #GMap-GStreet #GoogleMap iframe{
        max-width: 42vw;
    }
    body #container-contacts #TV #Lieu #GMap-GStreet #GoogleStreetView img{
        max-width: 42vw;
        height: auto;
    }
}