/******** A R R O W ***********/
#wrapper {
    display: table;
    width: 100%;
    position: absolute;
    bottom: 5vh;
    cursor:pointer;
  }
  
  #wrapper-inner {
    display: table-cell;
    vertical-align:middle;
    width:100%;
    height:100%;
    cursor:pointer;
  }
  
  #scroll-down {
      display: block;
      position: relative;
      padding-top: 79px;
    text-align:center;
    cursor:pointer;
  }
  .arrow-down {
      display: block;
      margin: 0 auto;
      width: 10px;
      height: 38px;
  }
  .arrow-down:after {
      content: '';
      display: block;
      margin: 0;
      padding: 0;
      width: 8px;
      height: 8px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      -moz-transform: rotate(135deg);
      -webkit-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
  }
  #scroll-title {
      display: block;
      text-transform: uppercase;
      color:#000;
    font-family: "Medium", sans-serif;
    font-size:14px;
    font-weight:bold;
    letter-spacing:.1em;
  }
  #scroll-down::before {
      animation: elasticus 1.2s cubic-bezier(1, 0, 0, 1) infinite;
      position: absolute;
      top: -2px;
      left: 49.95%;
      margin-left: -1px;
      width: 2px;
      height: 90px;
      background: linear-gradient(lightblue,#000);
      content: ' ';
  }
  
  @keyframes elasticus {
      0% {
          -webkit-transform-origin: 0% 0%;
          -ms-transform-origin: 0% 0%;
          -moz-transform-origin: 0% 0%;
          -o-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
          -webkit-transform: scale(1, 0);
          -ms-transform: scale(1, 0);
          -moz-transform: scale(1, 0);
          -o-transform: scale(1, 0);
          transform: scale(1, 0);
      }
      50% {
          -webkit-transform-origin: 0% 0%;
          -ms-transform-origin: 0% 0%;
          -moz-transform-origin: 0% 0%;
          -o-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
          -webkit-transform: scale(1, 1);
          -ms-transform: scale(1, 1);
          -moz-transform: scale(1, 1);
          -o-transform: scale(1, 1);
          transform: scale(1, 1);
      }
      50.1% {
          -webkit-transform-origin: 0% 100%;
          -ms-transform-origin: 0% 100%;
          -moz-transform-origin: 0% 100%;
          -o-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
          -webkit-transform: scale(1, 1);
          -ms-transform: scale(1, 1);
          -moz-transform: scale(1, 1);
          -o-transform: scale(1, 1);
          transform: scale(1, 1);
      }
      100% {
          -webkit-transform-origin: 0% 100%;
          -ms-transform-origin: 0% 100%;
          -moz-transform-origin: 0% 100%;
          -o-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
          -webkit-transform: scale(1, 0);
          -ms-transform: scale(1, 0);
          -moz-transform: scale(1, 0);
          -o-transform: scale(1, 0);
          transform: scale(1, 0);
      }
  }

@media screen and (min-width:1150px) {
    #wrapper{
        display: none;
    }
}
@media screen and (max-width:1150px) {
    #wrapper{
        display: table;
    }
}