@font-face {
  font-family: "Azonix";
  src: url("./../fonts/Azonix.otf") format("otf");
}
@font-face {
  font-family: "Poppins-Light";
  src: url("./../fonts/Poppins-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: url("./../fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  src: url("./../fonts/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Trajanus";
  src: url("./../fonts/TrajanusBricks.ttf") format("truetype");
}
body {
  padding: 0;
  margin: 0;
  @media screen and (min-width:800px) {
  nav {
    width: 100%;
    display: flex;
    justify-content: right;
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 60%;
      margin-right: 20px;
      li {
        list-style: none;
        a {
          color: #242424;
          font-family: "Poppins";
          text-decoration: none;
          background-image: linear-gradient(#789FA8, #789FA8);
          background-repeat: no-repeat;
          background-position: bottom left;
          background-size: 0% 3px;
          transition: background-size 500ms ease-in-out;
          font-size: 1.1em;
        }
        a:hover {
          color: inherit;
          background-size: 100% 3px;
        }
      }
    }
  }
}
#ttc{
  color:white;
  text-align: right;
  padding:5vh;
  padding-bottom: 0vh;
  margin:0
}
  #container {
    margin-top:8vh;
    max-width: 1470px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
    #Domaines-cards {
        h1 {
          font-family: "Poppins-Regular";
          color: #789fa8;
          font-size: 1.7em;
          margin-left: 40px;
        }
      

      #Premiere_colonne_Domaines {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        margin-top: 70px;
        flex-wrap: wrap;
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        article {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: calc(33.33% - 30px);
          margin-bottom: 30px;
          .icon {
            max-height: 200px;
            max-width: 200px;
            min-height: 200px;
            border-radius: 50px;
            min-width: 200px;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            img{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
                transition: 0.3s all ease-in-out;
                &:last-child{
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &:hover{
                img{
                    &:first-child{
                        opacity: 0;
                        visibility: hidden;
                    }
                    &:last-child{
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
          }
          p {
            font-family: "Poppins-Light";
            font-size: 1.5em;
            font-weight: 700;
            text-align: center;
            color: #789fa8;
          }
        }
      }
    //   #Deuxieme_colonne_Domaines {
    //     display: flex;
    //     justify-content: space-evenly;
    //     flex-direction: row;

    //     article {
    //       display: flex;
    //       flex-direction: column;
    //       justify-content: center;
    //       align-items: center;

    //       p {
    //         font-family: "Poppins-Light";
    //         font-size: 1em;
    //         color: #789fa8;
    //       }
    //       img {
    //         height: 150px;
    //         width: 150px;
    //       }
    //       #Marianne {
    //         height: 190px;
    //         width: 190px;
    //       }
    //     }
    //   }
    }
  }
}

.Domaines-content1{
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.884), rgba(255, 255, 255, 0.808)), url('../img/Limoges-HDV.jpg') center no-repeat;
    background-size: cover;
    padding: 50px 0;
    p,li{
      text-align: justify;
      width: 95%;
    }
    .container{
        margin-left: auto;
        margin-right: auto;
        padding: 0 5vw;
        h2{
            font-family: "Poppins-Light";
            font-size: 1.3em;
            font-weight: 600;
            color: #789fa8;
        }
        .icon-text{
            margin-top: 30px;
            display: flex;
            align-items: center;
            font-family: "Poppins-Light";
            font-size: 1.5em;
            font-weight: 600;
            color: #789fa8;
            img{
                height: 50px;
                width: 50px;
                margin-right: 15px;
            }
        }
        .icon-text1{
          font-size: 1em !important;
        }
        h3{
            font-family: "Poppins-Light";
            font-size: 1.5em;
            font-weight: 600;
            color: #789fa8;
        }
        a,strong{
          font-family: "Poppins-Light";
          font-size: 1em;
          text-indent: 30px;
          font-weight: 600;
          color: #789fa8;
          text-decoration: none;
          &:hover{
              text-decoration: underline;
          }
        }
        .domaines-list{
            list-style: none;
            padding-left: 0;
            margin-top: 30px;
            li{
                font-family: "Poppins-Light";
                font-size: 1.1em;
                text-indent: 30px;
                font-weight: 600;
                color: #000000;
                margin-bottom: 30px;
                position: relative;
                &::after{
                    content: "";
                    position: absolute;
                    height: 12px;
                    width: 12px;
                    background: #789fa8;
                    border-radius: 50px;
                    z-index: 2;
                    left: 10px;
                    top: 9px;
                }
            }
        }
        p{
          font-family: "Poppins-Light";
          font-size: 1.1em;
          color: #000000;
        }
    }
    &.ciel{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.884), rgba(255, 255, 255, 0.808)), url('../img/Limoges-ciel.jpg') center no-repeat;
      background-size: cover;
    }
    &.pont{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.884), rgba(255, 255, 255, 0.808)), url('../img/Limoges-pont.jpg') center no-repeat;
      background-size: cover;
    }
    &.hdv{
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.884), rgba(255, 255, 255, 0.808)), url('../img/Limoges-HDV2.jpg') center no-repeat;
      background-size: cover;
    }
}

.Domaines-content2{
    background: #fff;
}

.formulas{
  padding: 30px 0;
  h2{
    text-transform: uppercase;
    text-align: center;
    font-size: 60px;
    color: #fff;
    margin-top: 0;
    font-weight: 500;
  }
  background: #789FA8;
  .formolus-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    .formulus-item{
      max-height: 250px;
      max-width: 250px;
      min-height: 250px;
      border-radius: 20px;
      overflow: hidden;
      min-width: 250px;
      position: relative;
      margin: 25px 50px;
      img{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: 0.3s all ease-in-out;
        &:last-child{
            opacity: 0;
            visibility: hidden;
        }
      }
      &:hover{
          img{
              &:first-child{
                  opacity: 0;
                  visibility: hidden;
              }
              &:last-child{
                  opacity: 1;
                  visibility: visible;
              }
          }
      }
    }
  }
}

@media (max-width:900px){
    #Premiere_colonne_Domaines{
        article{
            width: calc(50% - 30px) !important;
        }
    }
}

@media (max-width:600px){
    #Premiere_colonne_Domaines{
        article{
            width: calc(100% - 30px) !important;
        }
    }
}

p.mobile{
  display: none;
}

@media screen and (max-width:800px){
  div#root div.container-domaines{
    overflow: hidden;
  }
  p.mobile{
    font-family: "Poppins-Light", sans-serif;
    font-size: 23px;
    margin:1vh 5% 1vh 11%;
    display: block;
  }
  section.Domaines-content1{
    position: absolute;
    top:0;
    z-index: -1;
    &.no-absolute{
      position: initial;
      top:unset;
      z-index: 1;
    }
    &.active{
      z-index: 1;
      background-color: white;
      top:70vh;
    }
    &:nth-of-type(2).active{
      top:120vh;
    }
    &:nth-of-type(3).active{
      top:155vh;
    }
    &:nth-of-type(4).active{
      top:195vh;
    }
    &:nth-of-type(5).active{
      top:222vh;
    }
    &:nth-of-type(6).active{
      top:263vh;
      li{
        width: auto!important;
      }
    }
  }
  div#container,footer{
    background-color: white;
  }
  section.Domaines-content1.Honoraires{
    position: initial;
  }
}